import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import AuthRoute from './components/auth-route';
import history from './utils/history';
import './app.scss';
import NotFoundPage from './pages/not-found/not-found';
import HomePage from './pages/home/home';
import AuthPage from './pages/auth/auth';
import BasePage from './pages/base/base';

const App = () => {
  return (
    <div className='App' role='none'>
      <Router history={history}>
        <Switch>
          <AuthRoute exact path='/' authType='unauthorized'>
            <HomePage />
          </AuthRoute>
          <AuthRoute path='/auth' authType='unauthorized'>
            <AuthPage />
          </AuthRoute>
          <AuthRoute path='/protected' authType='authorized'>
            <BasePage />
          </AuthRoute>
          <Route path='*'>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
