import { colors } from '../../constants/colors';

export default {
  wrapper: {
    margin: '-150px auto 0',
    maxWidth: '100%'
  },
  mainContainer: {
    padding: '0 50px',
    backgroundColor: '#f0f1ff'
  },
  header: {
    backgroundColor: '#5b2ad0',
    background: 'linear-gradient(45deg, #5b2ad0 0%, #5b2ad0 2%, #0089ff 100%)',
    paddingTop: 16,
    paddingBottom: 180,
    height: 'auto'
  },
  layout: {
    height: '100%',
    backgroundColor: '#000'
  },
  tableHeader: {
    overflow: 'hidden'
  },
  tableTitle: {
    color: '#777'
  },
  filteringLabel: {
    color: 'gray',
    fontSize: 'small',
    display: 'inline',
    marginLeft: '70px'
  },
  tableTitle2: {
    color: '#777'
  },
  card: {
    background: '#fff',
    borderRadius: 12,
    shadowColor: '#C0C1CC',
    marginBottom: 8,
    boxShadow: '0 1px 2px #C0C1CC'
  },
  card2: {
    background: '#fff',
    borderRadius: 12,
    shadowColor: '#C0C1CC',
    marginBottom: 8,
    boxShadow: '0 1px 2px #C0C1CC'
  },
  tableButtons: {
    float: 'right',
    overflow: 'hidden'
  },
  tableButton: {
    // marginRight: 20,
    marginLeft: 10,
    marginBottom: 16,
    fontWeight: 'bold'
  },
  searchInput: {
    width: '100%',
    padding: 10
  },
  containerActions: {
    padding: '0px 0px 20px'
  },
  containerButtons: {
    paddingTop: 10,
    display: 'inline-flex',
    width: '100%'
  },
  buttonActions: {
    margin: 8,
    width: '100%'
  },
  buttonModalBack: {
    backgroundColor: colors.white,
    borderRadius: 8,
    color: colors.stone_70,
    padding: '6px 8px',
    width: 228,
    height: 36,
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    cursor: 'pointer',
    border: `1px solid ${colors.stone_70}`,
    boxSizing: 'border-box',
    marginBottom: 35,
    marginTop: 35
  },
  buttonModalSave: {
    backgroundColor: colors.eva_50,
    borderRadius: 8,
    color: colors.white,
    padding: '6px 8px',
    width: 228,
    height: 36,
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    cursor: 'pointer',
    border: `1px solid ${colors.eva_50}`,
    marginBottom: 35,
    marginTop: 35
  }
};
